<script setup lang="ts">
import { RouterView } from 'vue-router'
import { onBeforeUnmount, onMounted } from 'vue'

const parseUnits = () => {
  const cvh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--cvh', `${cvh}px`)
}

window.addEventListener('resize', parseUnits)

onMounted(() => {
  parseUnits()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', parseUnits)
})
</script>

<template>
  <RouterView />
</template>
